/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Modal, Slide } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import { useReactTable, getCoreRowModel, flexRender, type ColumnDef, Cell, CellContext } from '@tanstack/react-table';
import React, { memo, useCallback, useMemo } from 'react';
import { useAccount } from '~/apis';
import { HorseStatistic, rounds, selectHorsesStatistics, useSelector, useTranslation } from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { useOnStateChange } from '~/utils';
import { cn } from '~/utils/styles';
import HorseCell, { HorseCellProps } from './HorseCell';
import UserCell, { UserCellProps } from './UserCell';

export type StatisticsModalState = 'closed' | 'timed' | 'open';

interface StatisticsProps {
    state: StatisticsModalState;
    onClose: () => void;
    duration?: number;
}

export type StatisticsColumnMeta = {
    collapse?: boolean;
};

type StatisticsCellValue = string | number | HorseCellProps | UserCellProps;

type StatisticsColumnType<T extends StatisticsCellValue> = ColumnDef<HorseStatistic, T> & {
    meta?: StatisticsColumnMeta;
};

const COLLAPSED_WIDTH = '0.0000000001%';
const DEFAULT_WIDTH = '1%';
const MIN_CELL_WIDTH = 60;

const getCellWidth = (meta: StatisticsColumnMeta | undefined) => (meta?.collapse ? COLLAPSED_WIDTH : DEFAULT_WIDTH);

const Statistics: React.FC<StatisticsProps> = ({
    state,
    onClose,
    duration = Turfmaster.endRoundTimeout,
}): JSX.Element => {
    const { t } = useTranslation();
    const { data: account, isLoading: isAccountLoading } = useAccount();
    const statistics = useSelector(selectHorsesStatistics);
    const isLoading = isAccountLoading || !statistics;

    const [{ width }] = useSpring<{ width: string }>(
        {
            from: { width: '100%' },
            to: { width: state === 'timed' ? '0%' : '100%' },
            config: { duration: state === 'timed' ? duration : 0 },
        },
        [state, duration],
    );

    const statisticsColumns = useMemo(
        () => [
            {
                id: 'rank',
                header: '#',
                accessorFn: (row) => (row.totalRank < 0 ? '-' : row.totalRank + 1),
                cell: (info) => info.getValue(),
                meta: { collapse: true },
            },
            {
                id: 'horse',
                header: t('game_statisticsHeaderHorse'),
                accessorFn: (row) =>
                    ({
                        color: row.color,
                        name: row.name,
                        jockeyName: row.jockeyName,
                    } as HorseCellProps),
                cell: (info) => <HorseCell {...(info as CellContext<HorseStatistic, HorseCellProps>)} />,
                meta: { collapse: false },
            },
            {
                id: 'user',
                header: t('game_statisticsHeaderPlayer'),
                accessorFn: (row) =>
                    ({
                        country: row.country,
                        name: row.playerName,
                    } as UserCellProps),
                cell: (info) => <UserCell {...(info as CellContext<HorseStatistic, UserCellProps>)} />,
                meta: { collapse: false },
            },
            ...rounds.map((round) => ({
                id: `${round}`,
                header: `${round + 1}`,
                accessorFn: (row) => (row.points[round] > 0 ? row.points[round] : '-'),
                meta: { collapse: true },
            })),
            {
                id: 'pointsSum',
                header: () => <span>&#x2211;</span>,
                accessorFn: (row) => row.pointsSum,
                meta: { collapse: true },
            },
        ],
        [t],
    ) as StatisticsColumnType<StatisticsCellValue>[];

    const table = useReactTable({
        data: statistics ?? [],
        columns: statisticsColumns,
        getCoreRowModel: getCoreRowModel(),
    });

    const renderCell = useCallback(
        (cell: Cell<HorseStatistic, unknown>) => (
            <td
                key={cell.id}
                className={cn('px-2 md:px-3 py-1 md:py-2', {
                    'text-right': (cell.column.columnDef.meta as StatisticsColumnMeta)?.collapse,
                })}
                style={{
                    width: getCellWidth(cell.column.columnDef.meta as StatisticsColumnMeta),
                    minWidth: (cell.column.columnDef.meta as StatisticsColumnMeta)?.collapse
                        ? MIN_CELL_WIDTH
                        : undefined,
                }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
        ),
        [],
    );

    useOnStateChange(
        state,
        useCallback(
            (newState) => {
                let timeout: number | undefined;
                if (newState === 'timed') {
                    timeout = window.setTimeout(onClose, duration);
                }
                return () => window.clearTimeout(timeout);
            },
            [duration, onClose],
        ),
    );

    const renderTable = () => {
        if (!statistics.length) {
            return <div className="text-center p-4">{t('game_statisticsNoData')}</div>;
        }

        return (
            <table className="w-full overflow-y-auto border-collapse">
                <thead className="border-b border-gray-400">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index, allHeaders) => (
                                <th
                                    key={header.id}
                                    className={cn('p-1 md:p-2 text-left', {
                                        'text-center': index === 0 || index > allHeaders.length - 5,
                                    })}>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody className="md:text-base lg:text-lg text-sm divide-y">
                    {table.getRowModel().rows.map((row) => (
                        <tr
                            key={row.id}
                            className={cn({
                                'bg-secondary bg-opacity-75': row.original.userId === account?.userId,
                            })}>
                            {row.getVisibleCells().map(renderCell)}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    if (isLoading) {
        return (
            <Modal disablePortal open={state !== 'closed'}>
                <div className="flex justify-center items-center h-full">
                    <div className="animate-spin">...</div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal disablePortal open={state !== 'closed'} onClose={onClose} style={{ pointerEvents: 'all' }}>
            <Slide direction="up" in={state !== 'closed'}>
                <div className="focus:outline-none flex flex-col items-stretch justify-center w-full h-full p-4 pointer-events-none">
                    <div className="bg-gray-50 relative w-full max-w-4xl mx-auto overflow-auto rounded-md shadow-lg pointer-events-auto">
                        <div className="md:p-6 flex flex-col items-stretch p-4">
                            <div className="flex flex-col space-y-2">
                                <h2>
                                    <strong>{t('game_statisticsTitle')}</strong>
                                </h2>

                                {renderTable()}
                            </div>
                        </div>

                        {state === 'timed' && (
                            <animated.div
                                className="top-0 absolute w-full h-1.5 rounded-md bg-primary"
                                style={{ width }}
                            />
                        )}

                        <button
                            className="top-2 right-2 md:w-8 md:h-8 md:p-2 absolute flex items-center justify-center w-6 h-6 p-1 bg-gray-200 border border-gray-400 rounded-full shadow-xl"
                            onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-full h-full">
                                <path
                                    d="M.75 23.249l22.5-22.5m0 22.5L.75.749"
                                    stroke="#000"
                                    fill="none"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};

export default memo(Statistics);
