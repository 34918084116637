/**
 * @author       Andreas Bayer <andreas@plusbyte.de>
 * @copyright    2015 Plusbyte UG (haftungsbeschränkt)
 * @license      {@link https://plusbyte.de Plusbyte License}
 */

import { v4 as uuid } from 'uuid';
import { ChatMessage, GameOverview, GameType } from './dtos';
import {
    Computer as ComputerProp,
    Horse as HorseProp,
    JoinGameRequest,
    NewGameRequest,
    Player as PlayerProp,
} from './dtos/controller';
import { Controller, ControllerType, View } from './interfaces';
import RandomNames from './randomnames';
import Computer from './tmcomputer';
import Turfmaster, { Board, Field, Game, Horse, Move, Player, StateMachineKey, StateMatrixKey } from './tmmodel';
import { randomInt } from './utilRandom';

/**
 * Controller
 * @class Turfmaster.Controller
 * @constructor
 */
class ControllerLocal implements Controller {
    private randomNames: RandomNames;

    public gameid = '';

    public timecreated = '';

    public playerViews: View[] = [];

    public log: any[] = [];

    public board: Board = new Board(0);

    public game: Game = new Game();

    public chatMessages: ChatMessage[] = [];

    public lastRemovedCards: number[] = [];

    public gameType: GameType = GameType.Training;

    public constructor(
        gameType: GameType = GameType.Training,
        gameid = '',
        timecreated = new Date().toISOString(),
        lastRemovedCards: number[] = [],
        game: Game = new Game(),
        chatMessages: ChatMessage[] = [],
        boardId = 0,
    ) {
        this.randomNames = new RandomNames();

        this.gameType = gameType;
        this.gameid = gameid;
        this.timecreated = timecreated;
        this.lastRemovedCards = lastRemovedCards;
        this.game = game;
        this.chatMessages = chatMessages;
        this.board = new Board(boardId);
    }

    public getType() {
        return ControllerType.LOCAL;
    }

    public getGame() {
        return this.game;
    }

    public getChatMessages() {
        return this.chatMessages;
    }

    public getBoard() {
        return this.board;
    }

    /**
     * ReceiveMsg
     * @method Turfmaster.Controller#receiveMsg
     * @param {Turfmaster.stateMachine} state - State
     * @param {Array} params - Params
     */
    public receiveMsg(state: number, params: any): false | void {
        this.updateLog(state, params);

        switch (state) {
            case Turfmaster.stateMachine.NEWGAME:
                this.newGameEvent(params);
                break;
            case Turfmaster.stateMachine.JOINGAME:
            case Turfmaster.stateMachine.JOINTOURNAMENT:
                this.joinGameEvent(params);
                break;
            case Turfmaster.stateMachine.MERGECARDS:
            case Turfmaster.stateMachine.ADDBONUSCARDS:
            case Turfmaster.stateMachine.SELECTCARDDICE:
            case Turfmaster.stateMachine.SELECTMOVE:
                // validate state and parameters
                if (!this.validateState(state)) {
                    console.error(
                        'error invalid state',
                        state,
                        Object.keys(Turfmaster.stateMachine).filter(
                            (key) => Turfmaster.stateMachine[key as StateMachineKey] === state,
                        )[0],
                    );
                    return false;
                } else if (params.playerid !== this.getPlayerId()) {
                    console.error(`playerid ${params.playerid} invalid, only current playerid ${this.getPlayerId()}
                        is allowed`);
                    return false;
                } else if (params.horseid !== this.getHorseId()) {
                    console.error(`horseid ${params.horseid} invalid, only current horseid ${this.getHorseId()}
                        is allowed`);
                    return false;
                }

                // act on player action
                switch (state) {
                    case Turfmaster.stateMachine.MERGECARDS:
                        this.mergeCardsEvent(params.horseid, params.added, params.removed);
                        break;
                    case Turfmaster.stateMachine.ADDBONUSCARDS:
                        this.addBonusCardsEvent(params.horseid);
                        break;
                    case Turfmaster.stateMachine.SELECTCARDDICE:
                        this.selectCardDiceEvent(params.horseid, params.carddiceid);
                        break;
                    case Turfmaster.stateMachine.SELECTMOVE:
                        this.selectMoveEvent(params.horseid, params.carddiceid, params.moveid);
                        break;
                }
                break;
            case Turfmaster.stateMachine.SELECTDICES:
                // validate state and parameters
                if (!this.validateState(state)) {
                    console.error(
                        'error invalid state',
                        state,
                        Object.keys(Turfmaster.stateMachine).filter(
                            (key) => Turfmaster.stateMachine[key as StateMachineKey] === state,
                        )[0],
                    );
                    return false;
                } else if (params.playerid !== this.game.turndiceplayerid) {
                    console.error(`playerid ${params.playerid} invalid, only current turndiceplayerid
                        ${this.game.turndiceplayerid} is allowed`);
                    return false;
                }

                this.selectDicesEvent(params.dicesid);
                break;
            case Turfmaster.stateMachine.NEWMESSAGE:
                this.newMessageEvent(params.message, params.userId);
                break;
        }
    }

    /**
     * SendMsg
     * @method Turfmaster.Controller#sendMsg
     * @param {Turfmaster.stateMachine} state - State
     * @param {Object} params - Params
     */
    public sendMsg(state: any, params: any): false | void {
        this.updateState(state);

        switch (state) {
            case Turfmaster.stateMachine.NEWGAME:
            case Turfmaster.stateMachine.JOINGAME:
                if (params.hasOwnProperty('playerid')) {
                    if (params.playerid === -999) {
                        break;
                    }

                    if (!this.playerViews[params.playerid]) {
                        console.error('error invalid playerid parameter');
                        return false;
                    }

                    this.playerViews[params.playerid].receiveMsg(state, params);
                } else {
                    // JOINGAME not possible: notify view about this
                    const view = params.view;
                    delete params.view;
                    view.receiveMsg(state, params);
                }
                break;
            case Turfmaster.stateMachine.JOINGAMEOPP:
            case Turfmaster.stateMachine.STARTGAME:
            case Turfmaster.stateMachine.STARTROUND:
            case Turfmaster.stateMachine.MERGECARDS:
            case Turfmaster.stateMachine.MERGECARDSOPP:
            case Turfmaster.stateMachine.STARTTURN:
            case Turfmaster.stateMachine.ADDBONUSCARDSOPP:
            case Turfmaster.stateMachine.SELECTCARDDICE:
            case Turfmaster.stateMachine.SELECTMOVE:
            case Turfmaster.stateMachine.SELECTMOVEOPP:
            case Turfmaster.stateMachine.SELECTDICES:
            case Turfmaster.stateMachine.SELECTDICESOPP:
            case Turfmaster.stateMachine.ENDTURN:
            case Turfmaster.stateMachine.ENDROUND:
            case Turfmaster.stateMachine.ENDGAME:
            case Turfmaster.stateMachine.NEWMESSAGE:
                for (let i = 0; i < this.game.players.length; i++) {
                    if (!this.playerViews[i]) {
                        console.error('error playerid not found parameter');
                        return false;
                    }

                    this.playerViews[i].receiveMsg(state, params);
                }
                break;
        }
    }

    /**
     * NewGameEvent
     * @method Turfmaster.Controller#newGameEvent
     * @param newGame
     * @param {Turfmaster.ViewLocal} view - View
     */
    public newGameEvent(newGame: NewGameRequest) {
        if (newGame.userid === '') {
            console.error('error newGameEvent: userid is empty');
        }

        if (!this.createBoard()) {
            console.error('error createBoard');
            return false;
        }

        // playerid should be > 0 for valid values where a game is created and joined immediately
        // playerid is -999 for a game without a player
        const playerid = this.createGame(newGame);

        if (playerid < 0 && playerid !== -999) {
            console.error('error createGame');
            return playerid;
        }

        this.sendMsg(Turfmaster.stateMachine.NEWGAME, { playerid: playerid, gameid: this.gameid });

        if (!this.isHorseLeft()) {
            this.startGame();
        }
    }

    public joinGameEvent(joinGame: JoinGameRequest) {
        if (joinGame.userid === '') {
            console.error('error addPlayer: userid is empty');
        }

        const playerid = this.addPlayer(joinGame.player, joinGame.view, joinGame.userid ?? uuid());

        if (playerid < 0) {
            console.error('error addPlayer');
            this.sendMsg(Turfmaster.stateMachine.JOINGAME, { error: playerid, view: joinGame.view });
            return playerid;
        }

        this.sendMsg(Turfmaster.stateMachine.JOINGAME, { playerid: playerid, chatmessages: this.chatMessages });
        this.sendMsg(Turfmaster.stateMachine.JOINGAMEOPP, { playerid: playerid });

        if (!this.isHorseLeft()) {
            this.startGame();
        }
    }

    /**
     * MergeCardsEvent
     * @method Turfmaster.Controller#mergeCardsEvent
     * @param horseid
     * @param {Array} added - Added
     * @param {Array} removed - removed
     */
    public mergeCardsEvent(horseid: any, added: any, removed: any) {
        if (!this.mergeCards(horseid, added, removed)) {
            console.error('error mergeCards');
            return false;
        }

        this.sendMsg(Turfmaster.stateMachine.MERGECARDSOPP, {});

        this.game.turnpos++;

        if (this.game.turnpos === this.game.turnorder.length) {
            this.calcRanks();
            this.calcHandi();
            this.calcTurnorder();

            this.startTurn();
        } else {
            this.lastRemovedCards = this.updateBonusCards(this.getHorseId());
            this.sendMsg(Turfmaster.stateMachine.MERGECARDS, { removedCards: this.lastRemovedCards });
        }
    }

    /**
     * AddBonusCardsEvent
     * @method Turfmaster.Controller#addBonusCardsEvent
     */
    public addBonusCardsEvent(horseid: any) {
        if (!this.addBonusCards(horseid)) {
            console.error('error addBonusCards');
            return false;
        }

        if (!this.game.horses[horseid]) {
            console.error('error horse invalid');
            return false;
        }

        const lastState = this.game.currentState;

        this.sendMsg(Turfmaster.stateMachine.ADDBONUSCARDSOPP, { horseid: horseid });

        if (lastState === Turfmaster.stateMachine.MERGECARDS) {
            this.sendMsg(Turfmaster.stateMachine.MERGECARDS, { removedCards: this.lastRemovedCards });
        } else if (lastState === Turfmaster.stateMachine.SELECTCARDDICE) {
            this.sendMsg(Turfmaster.stateMachine.SELECTCARDDICE, {});
        } else if (lastState === Turfmaster.stateMachine.SELECTMOVE) {
            const horseid = this.getHorseId();

            const moves = this.getMovesLogic(horseid, -1, false);

            if (!moves) {
                console.error('error getMovesLogic');
                return false;
            }

            if (moves.length > 0) {
                this.sendMsg(Turfmaster.stateMachine.SELECTMOVE, { moves: moves, carddice: -1 });
            } else {
                this.sendMsg(Turfmaster.stateMachine.SELECTCARDDICE, {});
            }
        } else if (lastState === Turfmaster.stateMachine.SELECTDICES) {
            this.sendMsg(Turfmaster.stateMachine.SELECTDICES, {});
        }
    }

    /**
     * SelectCardDiceEvent
     * @method Turfmaster.Controller#selectCardDiceEvent
     * @param horseid
     * @param {Number} carddiceid - Carddiceid
     */
    public selectCardDiceEvent(horseid: number, carddiceid: number) {
        const moves = this.getMovesLogic(horseid, carddiceid, true);

        if (!moves) {
            console.error('error getMovesLogic');
            return false;
        }

        const carddice = this.getCardDice(horseid, carddiceid);

        if ((moves as Move[]).length > 0) {
            this.sendMsg(Turfmaster.stateMachine.SELECTMOVE, { moves: moves, carddice: carddice });
        } else {
            this.sendMsg(Turfmaster.stateMachine.SELECTCARDDICE, {});
        }
    }

    /**
     * SelectMoveEvent
     * @method Turfmaster.Controller#selectMoveEvent
     * @param horseid
     * @param {Number} moveid - Moveid
     * @param {Number} carddiceid - Carddiceid
     */
    public selectMoveEvent(horseid: any, carddiceid: any, moveid: any) {
        if (!this.game.horses[horseid]) {
            console.error('error horse invalid');
            return false;
        }

        const horse: Horse = this.game.horses[horseid];

        const moves = this.getMovesLogic(horseid, carddiceid, false);

        if (!moves || !moves[moveid]) {
            if (moves && moves[0]) {
                moveid = 0;
            } else {
                console.error('error getMovesLogic');
                return false;
            }
        }

        const move = moves[moveid];

        const carddice: boolean | number = this.getCardDice(horseid, carddiceid);

        if (!carddice && carddice !== 0) {
            return false;
        }

        if (this.game.turntype === Turfmaster.turntypes.CARDS) {
            this.playCard(horseid, carddiceid);
        }

        if (move.drop) {
            this.removeHorse(horseid, false, true);
        } else {
            this.moveHorse(horseid, move.fields[move.fields.length - 1]);
        }

        this.sendMsg(Turfmaster.stateMachine.SELECTMOVEOPP, { move, carddice });

        if (!horse.field) {
            this.game.turnorder.splice(this.game.turnpos, 1);
        } else {
            this.game.turnpos++;
        }

        this.setNextMove();
    }

    /**
     * SelectDicesEvent
     * @method Turfmaster.Controller#selectDicesEvent
     * @param {Number} dicesid - Dicesid
     */
    public selectDicesEvent(dicesid: any) {
        if (dicesid < 0 || dicesid > this.game.turndices.length) {
            console.error('error invalid dicesid');
            return false;
        }

        this.game.turndicescur = dicesid;

        this.sendMsg(Turfmaster.stateMachine.SELECTDICESOPP, {});

        this.setNextMove();
    }

    public newMessageEvent(message: string, userId: string) {
        const msg: ChatMessage = {
            userId: userId,
            timestamp: new Date().toISOString(),
            message: message,
        };
        this.chatMessages.push(msg);

        this.sendMsg(Turfmaster.stateMachine.NEWMESSAGE, { message: msg });
    }

    /**
     * StartGameEvent
     * @method Turfmaster.Controller#startGame
     */
    public startGame() {
        this.game.turndiceplayerid = randomInt(0, this.game.players.length - 1);

        this.shuffle(this.game.horses);

        for (let i = 0; i < this.game.horses.length; i++) {
            this.moveHorse(i, this.board.fields[i][0]);
        }

        this.calcRanks();
        this.calcHandi();
        this.calcTurnorder();

        this.sendMsg(Turfmaster.stateMachine.STARTGAME, {});

        this.startRound();
    }

    /**
     * StartRoundEvent
     * @method Turfmaster.Controller#startRound
     */
    public startRound() {
        // reset 'dropped' and 'finished' properties for each new round
        this.game.horses.forEach((horse) => {
            horse.dropped = false;
            horse.finished = false;
        });

        this.sendMsg(Turfmaster.stateMachine.STARTROUND, {});

        if (this.game.round > 0) {
            const removedCards = this.updateBonusCards(this.getHorseId());
            this.sendMsg(Turfmaster.stateMachine.MERGECARDS, { removedCards: removedCards });
        } else {
            this.startTurn();
        }
    }

    /**
     * StartTurnEvent
     * @method Turfmaster.Controller#startTurn
     */
    public startTurn() {
        this.sendMsg(Turfmaster.stateMachine.STARTTURN, {});

        if (this.game.turntype === Turfmaster.turntypes.DICES) {
            this.calcTurndices();

            this.sendMsg(Turfmaster.stateMachine.SELECTDICES, {});
        } else {
            this.setNextMove();
        }
    }

    /**
     * SetNextMove
     * @method Turfmaster.Controller#setNextMove
     */
    public setNextMove(): void | boolean {
        if (this.game.turnpos === this.game.turnorder.length) {
            const horses = this.game.horses;
            const finishedhorseids: number[] = [];

            for (let i = 0; i < horses.length; i++) {
                if (this.isGoal(i) && !horses[i].finished) {
                    this.calcPoints(i);
                    finishedhorseids.push(i);
                }
            }

            for (let i = 0; i < horses.length; i++) {
                if (this.isGoal(i) && !horses[i].finished) {
                    this.removeHorse(i, true, false);
                }
            }

            this.calcRanks();
            this.calcHandi();
            this.calcTurnorder();

            // set timeout before ending the round
            if (finishedhorseids.length > 0) {
                setTimeout(() => {
                    this.endTurn(finishedhorseids);
                }, Turfmaster.finishedHorsesTurnTimeout);
            } else {
                this.endTurn(finishedhorseids);
            }
        } else {
            const horseid = this.getHorseId();

            if (this.isGoal(horseid)) {
                this.game.turnpos++;
                return this.setNextMove();
            }

            const moves = this.getMovesLogic(horseid, -1, false);

            if (!moves) {
                console.error('error getMovesLogic');
                return false;
            }

            if (moves.length > 0) {
                this.sendMsg(Turfmaster.stateMachine.SELECTMOVE, { moves: moves, carddice: -1 });
            } else {
                this.sendMsg(Turfmaster.stateMachine.SELECTCARDDICE, {});
            }
        }
    }

    /**
     * GetCardDice
     * @method Turfmaster.Controller#getCardDice
     * @param {Number} horseid - Horseid
     * @param {Number} carddiceid - Carddiceid
     */
    public getCardDice(horseid: number, carddiceid: number) {
        let carddice: number | boolean;

        if (this.game.turntype === Turfmaster.turntypes.CARDS) {
            const cards = this.getCurrentCards(horseid);

            // If horse has no cards for current round, let it stay it current position
            if (cards && cards.length === 0) {
                return 0;
            }

            if (carddiceid === -1) {
                return -1;
            }

            if (!cards || !cards[carddiceid]) {
                console.error('error getCurrentCards invalid');
                return false;
            }

            carddice = cards[carddiceid];
        } else {
            carddice = this.calcDicessum();

            if (!carddice) {
                console.error('error calcDicessum carddice not set');
                return false;
            }

            if (this.isHandi(horseid, carddice)) {
                const dices = this.getDices();

                // If dices are identical use one of them to skip select of dice
                if (dices[0] === dices[1]) {
                    carddiceid = 0;
                }

                if (carddiceid === -1) {
                    return carddiceid;
                }

                if (!dices || !dices[carddiceid]) {
                    console.error('error getDices invalid dices');
                    return false;
                }

                carddice = dices[carddiceid];
            }
        }

        return carddice;
    }

    /**
     * EndTurnEvent
     * @method Turfmaster.Controller#endTurn
     * @param {Array} finishedhorseids - Finishedhorseids
     */
    public endTurn(finishedhorseids: number[]) {
        this.sendMsg(Turfmaster.stateMachine.ENDTURN, { finishedhorseids: finishedhorseids });

        let foundHorse = false;
        for (let i = 0; i < this.game.horses.length; i++) {
            if (this.game.horses[i].field) {
                foundHorse = true;
                break;
            }
        }

        if (this.game.turntype === Turfmaster.turntypes.CARDS) {
            this.game.turntype = Turfmaster.turntypes.DICES;

            if (foundHorse) {
                let foundTurndiceplayer = false;

                do {
                    this.game.turndiceplayerid++;
                    if (this.game.turndiceplayerid === this.game.players.length) {
                        this.game.turndiceplayerid = 0;
                    }

                    // Check if turndiceplayer has horses in current round
                    for (let i = 0; i < this.game.horses.length; i++) {
                        if (this.game.horses[i].playerid === this.game.turndiceplayerid && this.game.horses[i].field) {
                            foundTurndiceplayer = true;
                            break;
                        }
                    }
                } while (!foundTurndiceplayer);
            }
        } else {
            this.game.turntype = Turfmaster.turntypes.CARDS;
        }

        if (!foundHorse) {
            this.endRound();
        } else {
            this.startTurn();
        }
    }

    /**
     * EndRoundEvent
     * @method Turfmaster.Controller#endRound
     */
    public endRound() {
        this.sendMsg(Turfmaster.stateMachine.ENDROUND, {});

        this.calcTurnorder(true);

        for (let i = 0; i < this.game.turnorder.length; i++) {
            this.moveHorse(this.game.turnorder[i], this.board.fields[i][0]);
        }

        this.calcRanks();
        this.calcTotalRanks();
        this.calcHandi();
        this.calcTurnorder();

        this.game.round++;
        this.game.turntype = Turfmaster.turntypes.CARDS;

        // Set Timeout before starting next round
        setTimeout(() => {
            if (this.game.round === Turfmaster.rounds) {
                this.endGame();
            } else {
                this.startRound();
            }
        }, Turfmaster.endRoundTimeout);
    }

    /**
     * EndGameEvent
     * @method Turfmaster.Controller#endGame
     */
    public endGame() {
        this.sendMsg(Turfmaster.stateMachine.ENDGAME, {});
    }

    /**
     * UpdateState
     * @method Turfmaster.Controller#updateState
     * @param {number} state - State
     */
    public updateState(state: number) {
        // message events are only transient and should not change the internal statemachine state
        if (state === Turfmaster.stateMachine.NEWMESSAGE || state === Turfmaster.stateMachine.SENDMESSAGE) {
            return;
        }

        this.game.currentState = state;
    }

    /**
     * ValidateState
     * @method Turfmaster.Controller#validateState
     * @param {Turfmaster.stateMachine} state - State
     */
    public validateState(state: number) {
        const states = Turfmaster.stateMatrix[this.game.currentState as StateMatrixKey];
        return states && states.indexOf(state) !== -1;
    }

    /**
     * UpdateLog
     * @method Turfmaster.Controller#updateLog
     * @param {Turfmaster.stateMachine} state - State
     * @param {Array} params - Params
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateLog(state: number, params: any) {
        // TODO currently deactivated because it is not saved yet
        // if (this.game) {
        //     this.log.push([state, params, JSON.stringify(this.game)]);
        // }
    }

    public createBoard() {
        this.board = new Board(0); // tbd boardid

        return true;
    }

    /**
     * CreateGame
     * @method Turfmaster.Controller#createGame
     * @param newGame
     * @param {Turfmaster.ViewLocal} view - View
     */
    public createGame(newGame: NewGameRequest) {
        this.playerViews = [];

        this.log = [];

        this.game = new Game();

        this.game.name = newGame.gameName;

        this.game.horseamount = newGame.horseamount;

        let playerid = -999;

        if (newGame.player !== undefined) {
            playerid = this.addPlayer(newGame.player, newGame.view, newGame.userid ?? uuid());

            if (playerid < 0) {
                console.error('error addPlayer invalid playerid');
                return playerid;
            }
        }

        if (newGame.computers !== undefined) {
            for (let i = 0; i < newGame.computers.length; i++) {
                const addComputerReturn = this.addComputer(newGame.computers[i]);
                if (addComputerReturn < 0) {
                    console.error('error addComputer');
                    return addComputerReturn;
                }
            }
        }

        return playerid;
    }

    /**
     * CreatePlayers
     * @method Turfmaster.Controller#createPlayers
     * @param player
     * @param view
     * @param userid
     */
    public addPlayer(player: PlayerProp, view: any, userid: string) {
        if (this.game.horses.length + player.horses.length > this.game.horseamount) {
            console.error('error player horses array invalid');
            return -1;
        }

        const tmpplayer = new Player();
        this.game.players.push(tmpplayer);
        const playerid = this.game.players.length - 1;

        tmpplayer.userid = userid;
        tmpplayer.name = player.name ?? this.randomNames.getRandomStudFarmName();
        tmpplayer.country = player.country ?? this.randomNames.getRandomCountryCode();
        tmpplayer.type = Turfmaster.playerTypes.PLAYER;

        this.playerViews[playerid] = view;

        const addHorsesReturn = this.addHorses(playerid, player.horses);
        if (addHorsesReturn < 0) {
            console.error('error addHorses');
            return addHorsesReturn;
        }

        return playerid;
    }

    public addComputer(player: ComputerProp) {
        if (this.game.horses.length + player.horses.length > this.game.horseamount) {
            console.error('error player horses array invalid');
            return -2;
        }

        const tmpplayer = new Player();
        this.game.players.push(tmpplayer);
        const playerid = this.game.players.length - 1;

        tmpplayer.userid = player.userid ?? uuid();
        tmpplayer.name = player.name ?? this.randomNames.getRandomStudFarmName();
        tmpplayer.country = player.country ?? this.randomNames.getRandomCountryCode();
        tmpplayer.type = Turfmaster.playerTypes.COMPUTER;

        switch (player.strength ?? Turfmaster.compStrengths.MEDIUM) {
            case Turfmaster.compStrengths.EASY:
                this.playerViews[playerid] = new Computer(playerid, Turfmaster.compStrengths.EASY, this);
                break;
            case Turfmaster.compStrengths.MEDIUM:
                this.playerViews[playerid] = new Computer(playerid, Turfmaster.compStrengths.MEDIUM, this);
                break;
            case Turfmaster.compStrengths.STRONG:
                this.playerViews[playerid] = new Computer(playerid, Turfmaster.compStrengths.STRONG, this);
                break;
        }

        const addHorsesReturn = this.addHorses(playerid, player.horses);
        if (addHorsesReturn < 0) {
            console.error('error addHorses');
            return addHorsesReturn;
        }

        return playerid;
    }

    public replacePlayerWithComputer(playerid: number, strength: number = Turfmaster.compStrengths.MEDIUM) {
        // userid, name, horses and type stays the same, only the view is updated
        this.playerViews[playerid] = new Computer(playerid, strength, this);

        return this.playerViews[playerid];
    }

    /**
     * CreateHorses
     * @method Turfmaster.Controller#createHorses
     * @param playerid
     * @param horses
     */
    public addHorses(playerid: number, horses: HorseProp[]) {
        for (let i = 0; i < horses.length; i++) {
            // TODO Check for valid horseid
            if (horses[i].horseid === '') {
                console.error('error horseid invalid');
                return -4;
            }
            // TODO Check for valid cardtype
            if (horses[i].cardtype === -1) {
                console.error('error cardtype invalid');
                return -5;
            }
        }

        let defcards: number[] = [];

        const rounds = Turfmaster.rounds;
        const cardsround = 10; // Math.floor(defcards.length / rounds);

        for (let i = 0; i < horses.length; i++) {
            const horse = new Horse();
            this.game.horses.push(horse);

            horse.horseid = horses[i].horseid ?? uuid();
            horse.playerid = playerid;
            horse.name = horses[i].name ?? this.randomNames.getRandomHorseName();
            horse.color = horses[i].color ?? -1;
            horse.jockeyName = horses[i].jockeyName ?? this.randomNames.getRandomJockeyName();
            // always enforce gold card deck
            horse.cardType = Turfmaster.cardTypes.GOLD;

            const cards: number[][] = [];
            horse.cards = cards;
            // always enforce gold card deck
            defcards = Turfmaster.cardsGold;
            this.shuffle(defcards);
            for (let k = 0; k < rounds; k++) {
                cards[k] = [];
                for (let l = 0; l < cardsround; l++) {
                    cards[k][l] = defcards[k * cardsround + l];
                }
                cards[k] = cards[k].slice().sort((a, b) => {
                    return a - b;
                });
            }
            cards.push([]);
            for (let k = 0; k < defcards.length - rounds * cardsround; k++) {
                cards[cards.length - 1][k] = defcards[rounds * cardsround + k];
            }
            cards[cards.length - 1] = cards[cards.length - 1].slice().sort((a, b) => {
                return a - b;
            });
        }

        return 0;
    }

    public getRanksOfRound(round: number): readonly number[] {
        const horses = this.game.horses;
        const ranks: number[] = [];

        for (let i = 0; i < horses.length; i++) {
            if (horses[i].ranks === undefined) {
                horses[i].ranks = [];
            }

            if (horses[i].ranks[round] !== undefined) {
                ranks.push(horses[i].ranks[round]);
            } else if (round === this.game.round) {
                ranks.push(horses[i].rank);
            } else {
                ranks.push(horses.length - 1);
            }
        }

        return ranks;
    }

    public getRanksOfHorse(horseid: number): readonly number[] {
        const horses = this.game.horses;
        const ranks: number[] = [];

        for (let i = 0; i < horses.length; i++) {
            ranks[i] = 0;
        }

        for (let i = 0; i < Turfmaster.rounds; i++) {
            if (horses[horseid].ranks === undefined) {
                horses[horseid].ranks = [];
            }

            if (horses[horseid].ranks[i] !== undefined) {
                ranks[horses[horseid].ranks[i]]++;
            } else if (i === this.game.round) {
                ranks[horses[horseid].rank]++;
            } else {
                ranks[horses.length - 1]++;
            }
        }

        return ranks;
    }

    /**
     * CalcRanks
     * @method Turfmaster.Controller#calcRanks
     */
    public calcRanks() {
        const horses = this.game.horses;
        const tmpFields: Field[] = [];
        const tmpRanks: number[] = [];
        let goalHorses = 0;

        for (let i = 0; i < horses.length; i++) {
            const field = horses[i].field;
            if (field) {
                tmpFields[i] = field;
            } else if (this.isGoal(i)) {
                tmpRanks[i] = horses[i].ranks[this.game.round];
                goalHorses++;
            }
        }

        const _this = this;
        const sorted = tmpFields.slice().sort(function (a, b) {
            return _this.compareFields(a, b);
        });
        const ranks = tmpFields.slice().map(function (v) {
            return sorted.indexOf(v);
        });
        const goalSorted = tmpRanks.slice().sort(function (a, b) {
            return a - b;
        });
        const goalRanks = tmpRanks.slice().map(function (v) {
            return goalSorted.indexOf(v);
        });

        for (let i = 0; i < horses.length; i++) {
            if (horses[i].field) {
                horses[i].rank = ranks[i] + goalHorses;
            } else if (this.isGoal(i)) {
                horses[i].rank = goalRanks[i];
            } else {
                // rank for dropped horses highest possible
                horses[i].rank = horses.length - 1;
            }
        }
    }

    public calcTotalRanks() {
        // calculate total rank for all horses
        const horses = this.game.horses;
        const tmpPoints: any[] = [];

        for (let i = 0; i < horses.length; i++) {
            let points = 0;
            if (horses[i].points.length > 0) {
                points = horses[i].points.reduce((total, num) => {
                    return total + num;
                }, 0);
            }
            tmpPoints[i] = {
                horse: horses[i],
                points: points,
                ranks: this.getRanksOfHorse(i),
            };
        }

        const sortedPoints = tmpPoints.slice().sort((a, b) => {
            if (b.points === a.points) {
                // TODO implement logic for same points
                for (let i = 0; i < horses.length; i++) {
                    if (b.ranks[i] !== a.ranks[i]) {
                        return b.ranks[i] - a.ranks[i];
                    }
                }
                if (Turfmaster.hurdleRounds.length > 0) {
                    const ranksOfRound = this.getRanksOfRound(Turfmaster.hurdleRounds[0]);
                    return ranksOfRound[tmpPoints.indexOf(b)] - ranksOfRound[tmpPoints.indexOf(a)];
                } else {
                    return 0;
                }
            } else {
                return b.points - a.points;
            }
        });

        const rankPoints = tmpPoints.slice().map(function (v) {
            return sortedPoints.indexOf(v);
        });

        for (let i = 0; i < horses.length; i++) {
            horses[i].totalrank = rankPoints[i];
        }

        // ####################################
        // calculate total rank for all players

        // 0. init array
        const pointsForPlayer: { playerid: number; pointsSum: number; highestHorseTotalrank: number }[] = [];
        for (let p = 0; p < this.game.players.length; p++) {
            // find highest horse totalrank for this player
            let highestHorseTotalrank = -1;
            this.game.horses.forEach((horse) => {
                if (horse.playerid === p && horse.totalrank > highestHorseTotalrank) {
                    highestHorseTotalrank = horse.totalrank;
                }
            });

            pointsForPlayer.push({ playerid: p, pointsSum: 0, highestHorseTotalrank });
        }

        // 1. sum up points for each player
        for (let i = 0; i < horses.length; i++) {
            const horse = horses[i];

            // calculate sum of points for horse
            const pointsSum = horse.points.reduce((sum: number, current: number) => {
                return sum + current;
            }, 0);

            const playerToUpdate = pointsForPlayer.find((p) => p.playerid === horse.playerid);
            if (playerToUpdate !== undefined) {
                playerToUpdate.pointsSum += pointsSum;
            }
        }

        // 2. sort by pointsSum for each player
        // if two elements in pointsForPlayer have the same sum, check who has the horse with the highest horse.totalrank
        const sortedPointsForPlayer = pointsForPlayer.slice().sort((a, b) => {
            if (a.pointsSum === b.pointsSum) {
                return b.highestHorseTotalrank - a.highestHorseTotalrank;
            } else {
                return b.pointsSum - a.pointsSum;
            }
        });

        // 3. assign player.totalranks based on sorted array
        for (let r = 0; r < sortedPointsForPlayer.length; r++) {
            const playerData = sortedPointsForPlayer[r];
            this.game.players[playerData.playerid].totalrank = r;
        }
    }

    /**
     * CalcHandi
     * @method Turfmaster.Controller#calcHandi
     */
    public calcHandi() {
        const horses = this.game.horses;
        const tmpprogs: number[] = [];
        let goalHorses = 0;

        for (let i = 0; i < horses.length; i++) {
            const field = horses[i].field;
            if (this.isGoal(i)) {
                goalHorses++;
            } else if (field) {
                tmpprogs[i] = field.prog;
            }
        }

        const sorted = tmpprogs.slice().sort((a, b) => {
            return b - a;
        });
        const handi = tmpprogs.slice().map((v) => {
            return sorted.indexOf(v);
        });

        for (let i = 0; i < horses.length; i++) {
            if (horses[i].field) {
                horses[i].handi = handi[i] + goalHorses;
            } else {
                horses[i].handi = -1;
            }
        }
    }

    /**
     * CalcPoints
     * @method Turfmaster.Controller#calcPoints
     */
    public calcPoints(horseid: number) {
        if (isNaN(horseid)) {
            console.error('error horseid is not a number');
            return false;
        }

        const horses = this.game.horses;
        const points = Turfmaster.points;
        let rank = 0;

        if (!horses[horseid]) {
            console.error('error invalid horseid');
            return false;
        }

        for (let i = 0; i < horses.length; i++) {
            if (
                i !== horseid &&
                this.isGoal(i) &&
                (horses[i].finished || this.compareFields(horses[horseid].field, horses[i].field) > 0)
            ) {
                rank++;
            }
        }

        if (!horses[horseid].points) {
            horses[horseid].points = [];
        }

        if (rank >= 0 && points[rank]) {
            horses[horseid].points.push(points[rank]);
        } else {
            horses[horseid].points.push(0);
        }

        if (horses[horseid].ranks === undefined) {
            horses[horseid].ranks = [];
        }

        horses[horseid].ranks.push(rank);
    }

    /**
     * calcTurnorder
     * @method Turfmaster.Controller#calcTurnorder
     */
    public calcTurnorder(allHorses = false) {
        const horses = this.game.horses;
        let tmphorses: Horse[] = [];

        if (!allHorses) {
            for (let i = 0; i < horses.length; i++) {
                if (horses[i].field) {
                    tmphorses.push(horses[i]);
                }
            }
        } else {
            tmphorses = horses;
        }

        const sorted = tmphorses.slice().sort((a, b) => {
            return a.rank - b.rank;
        });

        this.game.turnorder = sorted.slice().map((v) => {
            return horses.indexOf(v);
        });

        this.game.turnpos = 0;
    }

    /**
     * calcTurndices
     * @method Turfmaster.Controller#calcTurndices
     */
    public calcTurndices() {
        const dices: number[] = [];
        this.game.turndices = dices;

        for (let i = 0; i < Turfmaster.dices; i++) {
            dices[i] = randomInt(1, 6);
        }
    }

    /**
     * calcDicessum
     * @method Turfmaster.Controller#calcDicessum
     * @return {Number} Dicessum
     */
    public calcDicessum() {
        const dices = this.getDices();

        if (!dices) {
            console.error('error getDices invalid dices array');
            return false;
        }

        let dicessum = 0;

        for (let i = 0; i < dices.length; i++) {
            dicessum += dices[i];
        }

        return dicessum;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getCountOfBonusCards(horseId: number) {
        // always enforce gold card deck
        const defcards: number[] = Turfmaster.cardsGold;

        const rounds = Turfmaster.rounds;
        const cardsround = 10; // Math.floor(defcards.length / rounds);

        return defcards.length - rounds * cardsround;
    }

    /**
     * UpdateBonusCards
     * @method Turfmaster.Controller#updateBonusCards
     */
    public updateBonusCards(horseId: number): number[] {
        const horse: Horse = this.game.horses[horseId];
        const removed: number[] = [];

        if (horse.bonuscards === this.game.round - 1) {
            const cards: number[] = horse.cards[this.game.round - 1];
            const bonuscards = this.getCountOfBonusCards(horseId);

            for (let i = 0; i < bonuscards; i++) {
                if (cards.length > 0) {
                    const removeId = randomInt(0, cards.length - 1);
                    removed.push(cards[removeId]);
                    cards.splice(removeId, 1);
                }
            }
        }

        if (horse.bonuscards === -1 && this.game.round === Turfmaster.rounds - 1) {
            this.addBonusCards(horseId);
        }

        return removed;
    }

    /**
     * AddBonusCards
     * @method Turfmaster.Controller#addBonusCards
     */
    public addBonusCards(horseid: number) {
        const cards = this.getCurrentCards(horseid);

        const horse = this.game.horses[horseid];
        let bonuscards: number[] | false;

        if (!horse) {
            console.error('error invalid horseid parameter');
            bonuscards = false;
        } else {
            bonuscards = horse.cards[Turfmaster.rounds];
        }

        if (!cards || !bonuscards || !this.game.horses[horseid] || bonuscards.length === 0) {
            console.error('error invalid values');
            return false;
        }

        for (let i = 0; i < bonuscards.length; i++) {
            horse.cards[this.game.round].push(bonuscards[i]);
        }

        horse.bonuscards = this.game.round;

        bonuscards.splice(0, bonuscards.length);

        horse.cards[this.game.round] = cards.slice().sort((a, b) => {
            return a - b;
        });

        return true;
    }

    /**
     * MoveHorse
     * @method Turfmaster.Controller#moveHorse
     * @param horseid
     * @param field
     */
    public moveHorse(horseid: number, field: Field) {
        if (!(field instanceof Field)) {
            console.error('error invalid field parameter');
            return false;
        }

        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse = this.game.horses[horseid];

        horse.field = field;
    }

    public removeHorse(horseid: number, finished: boolean, dropped: boolean) {
        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse = this.game.horses[horseid];

        horse.field = null;
        horse.dropped = dropped;
        horse.finished = finished;

        // For dropped horses remove current cards
        if (dropped) {
            const cards = this.getCurrentCards(horseid);
            if (!cards) {
                console.error('error cards invalid');
                return false;
            }
            horse.cards[this.game.round].splice(0, cards.length);
        }
    }

    /**
     * MergeCards
     * @method Turfmaster.Controller#mergeCards
     * @param horseid
     * @param {Array} added - Added
     * @param {Array} removed - Removed
     */
    public mergeCards(horseid: number, added: number[], removed: number[]) {
        if (
            !(added instanceof Array) ||
            !(removed instanceof Array) ||
            added.length !== removed.length ||
            !this.game.horses[horseid]
        ) {
            console.error('error invalid parameters');
            return false;
        }

        const horse = this.game.horses[horseid];

        const lastcards = this.getLastCards(horseid);
        let cards = this.getCurrentCards(horseid);

        if (!lastcards || !cards) {
            console.error('error lastcards or cards invalid');
            return false;
        }

        cards = [
            ...cards.filter((_card: number, index: number) => !removed.includes(index)),
            ...lastcards.filter((_card, index) => added.includes(index)),
        ];

        horse.cards[this.game.round - 1].splice(0, lastcards.length);

        horse.cards[this.game.round] = cards.slice().sort((a, b) => {
            return a - b;
        });

        return true;
    }

    /**
     * PlayCard
     * @method Turfmaster.Controller#playCard
     * @param horseid
     * @param {Number} carddiceid - Carddiceid
     */
    public playCard(horseid: number, carddiceid: number) {
        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse: Horse = this.game.horses[horseid];

        const cards = horse.cards[this.game.round];

        if (!cards || !cards[carddiceid]) {
            console.error('error horse cards invalid');
            return false;
        }

        horse.cards[this.game.round].splice(carddiceid, 1);
    }

    /**
     * GetBonusCards
     * @method Turfmaster.Controller#getBonusCards
     */
    public getBonusCards(horseid: number) {
        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse = this.game.horses[horseid];

        return horse.cards[Turfmaster.rounds];
    }

    /**
     * getDices
     * @method Turfmaster.Controller#getDices
     */
    public getDices() {
        const dices = this.game.turndices;
        const id = this.game.turndicescur;

        if (dices[id]) {
            return [dices[id]];
        } else {
            return dices;
        }
    }

    public getGameOverview(): GameOverview | null {
        // TODO add filtering of game - why not use getGameForPlayerId without playerid
        if (this.game) {
            return {
                gameId: this.gameid,
                timeCreated: this.timecreated,
                name: this.game.name,
                gameType: this.gameType,
                horseamount: this.game.horseamount,
                players: this.game.players,
                horses: this.game.horses,
            };
        }

        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getGameForPlayerId(playerid: number) {
        // TODO add filtering for certain playerid and add default filter without playerid
        return this.game;
    }

    public getGameSnapshot(): Game | null {
        return this.game;
    }

    /**
     * returns the horseId for the horse whose turn it is
     * @method Turfmaster.Controller#getHorseId
     */
    public getHorseId(): number {
        return this.game.turnorder[this.game.turnpos];
    }

    /**
     * GetMoves
     * @method Turfmaster.Controller#getMoves
     * @param horseid
     * @param {Number} count - Count
     * @return {Array} Moves
     */
    public getMoves(horseid: number, count: number) {
        if (isNaN(count)) {
            console.error('error invalid count parameter');
            return false;
        }

        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse = this.game.horses[horseid];
        const fields = this.board.fields;

        if (!horse || !horse.field) {
            console.error('error invalid horse or horse field');
            return false;
        }

        // Array to store all possible move paths
        const moves: Move[] = [];
        // Array to track how many steps each move has used
        const movecount: number[] = [];
        // Array to track if a horse has changed lanes in a move
        // Only at most two lane changes are allowed
        // The first step can be a lane change
        // At least the next five have to be straight
        // After that, a horse can change lanes again only once after the 6th step
        // If this is true, the horse has changed lanes in this move or it is too late to change lanes
        const turned: boolean[] = [];

        let tmpmoves;
        let tmpmovecount;
        let hurdle;
        let block;
        let field;
        let jump = 5; // After 5 steps, a horse can change lanes only once

        count = Math.abs(count);

        moves[0] = new Move();
        moves[0].fields.push(horse.field); // Start with current horse position
        movecount[0] = 0; // No steps taken yet

        for (let i = 0; i < count; i++) {
            tmpmoves = [];
            tmpmovecount = [];
            for (let j = 0; j < moves.length; j++) {
                // Only continue moves that haven't reached the maximum step count
                if (movecount[j] < count) {
                    field = moves[j].fields[moves[j].fields.length - 1];
                    // First step or after 5 steps, a horse can change lanes
                    if (movecount[j] === 0 || (movecount[j] > jump && turned[j] !== true)) {
                        // Check if left lane change is possible
                        hurdle = this.isHurdle(field.lane - 1, field.nl);
                        block = this.isBlock(horseid, field.lane - 1, field.nl);
                        if (field.nl !== -1 && !block && !hurdle) {
                            tmpmoves.push(new Move());
                            for (let k = 0; k < moves[j].fields.length; k++) {
                                tmpmoves[tmpmoves.length - 1].fields[k] = moves[j].fields[k];
                            }
                            tmpmoves[tmpmoves.length - 1].fields[moves[j].fields.length] =
                                fields[field.lane - 1][field.nl];
                            tmpmovecount[tmpmoves.length - 1] = movecount[j] + 1;
                        }

                        // Check if right lane change is possible
                        hurdle = this.isHurdle(field.lane + 1, field.nr);
                        block = this.isBlock(horseid, field.lane + 1, field.nr);
                        if (field.nr !== -1 && !block && !hurdle) {
                            tmpmoves.push(new Move());
                            for (let k = 0; k < moves[j].fields.length; k++) {
                                tmpmoves[tmpmoves.length - 1].fields[k] = moves[j].fields[k];
                            }
                            tmpmoves[tmpmoves.length - 1].fields[moves[j].fields.length] =
                                fields[field.lane + 1][field.nr];
                            tmpmovecount[tmpmoves.length - 1] = movecount[j] + 1;
                        }
                    }

                    // Check if the horse can move forward
                    if (field.nf !== -1) {
                        hurdle = this.isHurdle(field.lane, field.nf);
                        block = this.isBlock(horseid, field.lane, field.nf);
                        if (!block) {
                            if (!hurdle) {
                                moves[j].fields[moves[j].fields.length] = fields[field.lane][field.nf];
                                movecount[j] += 1;
                            } else {
                                block = this.isBlock(horseid, field.lane, fields[field.lane][field.nf].nf);
                                if (count > movecount[j] + 3 && fields[field.lane][field.nf].nf !== -1 && !block) {
                                    moves[j].fields[moves[j].fields.length] = fields[field.lane][field.nf];
                                    moves[j].fields[moves[j].fields.length] =
                                        fields[field.lane][fields[field.lane][field.nf].nf];
                                    movecount[j] += 4;
                                    // Check if horse is direct in front of hurdle to set jump after 7 and not 6 fields
                                    if (
                                        moves.length === 1 &&
                                        this.isHurdle(moves[0].fields[0].lane, moves[0].fields[0].nf)
                                    ) {
                                        jump = 6;
                                    }
                                }
                            }
                        }
                    } else {
                        moves[j].drop = true;
                    }
                }
            }
            for (let j = 0; j < tmpmoves.length; j++) {
                moves.push(tmpmoves[j]);
                movecount[moves.length - 1] = tmpmovecount[j];
                if (movecount[moves.length - 1] > jump) {
                    turned[moves.length - 1] = true;
                }
            }
        }

        let maxcount;
        let max = 0;

        for (let i = 0; i < moves.length; i++) {
            maxcount = 0;
            for (let j = 0; j < moves[i].fields.length; j++) {
                hurdle = this.isHurdle(moves[i].fields[j].lane, moves[i].fields[j].pos);
                if (hurdle) {
                    maxcount += 3;
                } else {
                    maxcount += 1;
                }
            }
            if (max < maxcount) {
                max = maxcount;
            }
        }

        let returnmoves: Move[] = [];
        let returncount = 0;
        let found: boolean;

        for (let i = 0; i < moves.length; i++) {
            maxcount = 0;
            for (let j = 0; j < moves[i].fields.length; j++) {
                hurdle = this.isHurdle(moves[i].fields[j].lane, moves[i].fields[j].pos);
                if (hurdle) {
                    maxcount += 3;
                } else {
                    maxcount += 1;
                }
            }
            if (maxcount === max) {
                found = false;
                for (let k = 0; k < returnmoves.length; k++) {
                    if (
                        returnmoves[k].fields[returnmoves[k].fields.length - 1] ===
                        moves[i].fields[moves[i].fields.length - 1]
                    ) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    returnmoves[returncount] = moves[i];
                    returncount++;
                }
            }
        }

        const _this = this;
        returnmoves = returnmoves.slice().sort((a, b) => {
            return _this.compareFields(a.fields[a.fields.length - 1], b.fields[b.fields.length - 1]);
        });

        return returnmoves;
    }

    /**
     * GetMovesLogic
     * @method Turfmaster.Controller#getMovesLogic
     * @param {Number} horseid - Horseid
     * @param {Number} carddiceid - Carddiceid
     * @param {Boolean} skipHandi - SkipHandi
     * @return {Array} Moves
     */
    public getMovesLogic(horseid: number, carddiceid: number, skipHandi: boolean): readonly Move[] | false {
        let carddice = this.getCardDice(horseid, carddiceid);

        if (!carddice && carddice !== 0) {
            return false;
        }

        if (carddice === -1) {
            return [];
        }

        if (this.game.turntype === Turfmaster.turntypes.CARDS) {
            if (skipHandi !== true && this.isHandi(horseid, carddice)) {
                carddice = 0;
            }

            const moves = this.getMoves(horseid, carddice);

            if (!moves) {
                console.error('error getMoves invalid result');
                return false;
            }

            let field: Field;
            let hurdle: boolean;

            for (let i = 0; i < moves.length; i++) {
                field = moves[i].fields[moves[i].fields.length - 1];
                hurdle = this.isHurdle(field.lane, field.nf);
                if (moves[i].fields.length !== Math.abs(carddice) + 1 && hurdle) {
                    moves[i].fields[moves[i].fields.length] = this.board.fields[field.lane][field.nf];
                    moves[i].drop = true;
                }
            }
            return moves;
        } else {
            const moves = this.getMoves(horseid, carddice);

            if (!moves) {
                console.error('error getMoves invalid result');
                return false;
            }
            return moves;
        }
    }

    /**
     * GetCurrentCards
     * @method Turfmaster.Controller#getCurrentCards
     */
    public getCurrentCards(horseid: number): false | readonly number[] {
        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse: Horse = this.game.horses[horseid];

        return horse.cards[this.game.round];
    }

    /**
     * GetLastCards
     * @method Turfmaster.Controller#getLastCards
     */
    public getLastCards(horseid: number): null | false | readonly number[] {
        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse = this.game.horses[horseid];

        if (this.game.round - 1 >= 0) {
            return horse.cards[this.game.round - 1];
        }

        return null;
    }

    /**
     * returns the playerId for the player whose turn it is
     * @method Turfmaster.Controller#getPlayerId
     */
    public getPlayerId() {
        const horseid = this.getHorseId();

        if (!this.game.horses[horseid]) {
            console.error('error getHorseId invalid horseid result');
            return false;
        }

        const horse: Horse = this.game.horses[horseid];

        return horse.playerid;
    }

    public isBlock(horseid: number, lane: number, pos: number) {
        const horses: Horse[] = this.game.horses;

        for (let i = 0; i < horses.length; i++) {
            if (
                i !== horseid &&
                horses[i] &&
                horses[i].field &&
                horses[i].field!.lane === lane &&
                horses[i].field!.pos - 1 <= pos &&
                horses[i].field!.pos + 1 >= pos
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * IsGoal
     * @method Turfmaster.Controller#isGoal
     * @return {Boolean} True/False
     */
    public isGoal(horseid: number): boolean {
        const horses = this.game.horses;

        if (!horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const field = horses[horseid].field;
        if (horses[horseid].dropped || (field && field.prog < this.board.goal)) {
            return false;
        }

        return true;
    }

    /**
     * IsHandi
     * @method Turfmaster.Controller#isHandi
     * @param horseid
     * @param {Number} count - Count
     * @return {Boolean} True/False
     */
    public isHandi(horseid: number, count: number) {
        if (isNaN(count)) {
            console.error('error invalid count parameter');
            return false;
        }

        if (!this.game.horses[horseid]) {
            console.error('error invalid horseid parameter');
            return false;
        }

        const horse: Horse = this.game.horses[horseid];
        const handi = Turfmaster.handi;

        for (let i = 0; i < handi.length; i++) {
            if (horse.handi === i && count > handi[i]) {
                return true;
            }
        }

        return false;
    }

    public isHorseLeft() {
        return this.game.horses.length < this.game.horseamount;
    }

    public isHurdle(lane: number, pos: number) {
        return (
            Turfmaster.hurdleRounds.indexOf(this.game.round) !== -1 &&
            this.board.fields[lane] &&
            this.board.fields[lane][pos] &&
            this.board.fields[lane][pos].hurdle
        );
    }

    /**
     * CompareFields
     * @method Turfmaster.Controller#compareFields
     * @param {Object} a - A
     * @param {Object} b - B
     */
    public compareFields(a: Field | null, b: Field | null) {
        if (!(a instanceof Field) || !(b instanceof Field)) {
            return 0;
        }

        const tolerance = 0.01;

        if (Math.abs(a.prog - b.prog) < tolerance) {
            return a.lane - b.lane;
        } else if (a.prog < b.prog) {
            return 1;
        } else {
            return -1;
        }
    }

    public shuffle(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = randomInt(0, i);
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
}

export default ControllerLocal;
