/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { animated, SpringConfig, useSpring } from '@react-spring/web';
import { easeQuadOut } from 'd3-ease';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    HorseColorValues,
    selectHorse,
    selectHorseDropped,
    selectHorseField,
    selectHorseFinished,
    selectHorseHandi,
    selectHorseRanksCorrected,
    selectHorsesCount,
    selectIsGameRunning,
    selectIsOwnHighlightedHorse,
    selectIsOwnHorseIndex,
    selectPlayer,
    selectRound,
    useSelector,
} from '~/app';
import HorseIcon from '~/components/HorseIcon';
import HorseInfo from '~/components/HorseIcon/HorseInfo';
import Turfmaster from '~/server/src/tmmodel';
import { ch, cw, transformRelX, transformRelY, vh, vw } from '~/utils';
import { cn } from '~/utils/styles';
import * as styles from './Horse.module.css';

export interface HorseProps {
    /**
     * Horse id is basically the index of the horse data object in game state.
     */
    index: number;
}

const Horse = ({ index }: HorseProps) => {
    const analytics = useFirebaseAnalytics();
    const currentRound = useSelector(selectRound);
    const horse = useSelector((state) => selectHorse(state, index));
    const horseCount = useSelector(selectHorsesCount);
    const horseColor = horse?.color ?? 0;
    const horseRealColor = horse?.realColor ?? -1;
    const horseName = horse?.name ?? '';
    const isDropped = useSelector((state) => selectHorseDropped(state, index));
    const horseFinished = useSelector((state) => selectHorseFinished(state, index));
    const { rank, totalRank } = useSelector((state) => selectHorseRanksCorrected(state, index));
    const horseHandi = useSelector((state) => selectHorseHandi(state, index));
    const horsePlayerId = horse ? horse.playerId : -1;
    const horsePlayer = useSelector((state) => selectPlayer(state, horsePlayerId));
    const isOwnHorse = useSelector((state) => selectIsOwnHorseIndex(state, index));

    const playerName = horsePlayer ? horsePlayer.name : '';
    const isGameRunning = useSelector(selectIsGameRunning);

    const isOwnHighlightedHorse = useSelector((state) => selectIsOwnHighlightedHorse(state, index));

    const w = `2.5vw`;
    const h = `2.5vw`;
    const field = useSelector((state) => selectHorseField(state, index));
    const { y, move } =
        isGameRunning && !isDropped && !horseFinished && field
            ? field
            : { y: ch / 2, move: [{ x: cw / 2, y: ch / 2 }] };

    const queue = useRef([{ x: 0, y: 0, length: 0 }]);
    const [{ xy }, animatedPositionApi] = useSpring(
        () => ({
            xy: [0, 0],
            config: { duration: 150 },
        }),
        [],
    );

    const updatePosition = useCallback(() => {
        const { x: nextX, y: nextY, length } = queue.current.length ? queue.current[0] : { x: -1, y: -1, length: -1 };
        const relX = transformRelX(nextX);
        const relY = transformRelY(nextY);

        animatedPositionApi.start({
            xy: [relX, relY],
            onRest: () => {
                if (queue.current.length > 1) {
                    queue.current = queue.current.slice(1);
                    updatePosition();
                }
            },
            config:
                queue.current.length > 1
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ({ duration: 150 * length } as any)
                    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ({ duration: 300, easing: easeQuadOut } as any),
        });
    }, [animatedPositionApi]);

    useEffect(() => {
        const duplicateCheckedQueue = move ? (move.length > 1 ? move.slice(1) : move) : [];
        const normalizedQueue = duplicateCheckedQueue.slice(0, 1).map((pos) => {
            return { ...pos, length: 1 };
        });
        duplicateCheckedQueue.slice(1).forEach((newPos, index) => {
            const lastIndex = normalizedQueue.length - 1;
            const lastPos = normalizedQueue[lastIndex];
            if (normalizedQueue.length > 1 && lastIndex !== index && (lastPos.x === newPos.x || lastPos.y === newPos.y))
                normalizedQueue[lastIndex] = { ...newPos, length: lastPos.length + 1 };
            else normalizedQueue.push({ ...newPos, length: 1 });
        });
        queue.current = normalizedQueue;
        updatePosition();
    }, [move, updatePosition]);

    const transform = (px: number, py: number) =>
        `translate3d(calc(${
            isGameRunning && !isDropped && !horseFinished
                ? `${px} * 100vw - (${w} / 2)`
                : `50vw + ${w} * 1.2 * ${index} - ${w} * 1.2 * ${horseCount} / 2`
        }), calc(${py} * (100vw / ${vw} * ${vh}) - (${h} / 2)), 0)`;

    const [{ rotation }, animatedRotationApi] = useSpring<{ rotation: number; config: SpringConfig }>(
        () => ({
            rotation: 0,
            config: { tension: 280, friction: 160 },
        }),
        [],
    );
    useEffect(() => {
        isDropped && animatedRotationApi.start({ rotation: rotation.get() > 0 ? 0 : 1440 });
    }, [isDropped, rotation, animatedRotationApi]);

    return (
        <animated.div
            className={cn(styles.HorseRoot, { [styles.HorseRootCurrent]: isOwnHighlightedHorse })}
            style={{
                width: w,
                height: h,
                transform: xy.to(transform),
            }}>
            <div className={cn(styles.HorseAnimationEntrance, 'w-full h-full')}>
                <HorseIcon
                    className={cn('w-full h-full drop-shadow-xl', { [styles.HorseCurrent]: isOwnHighlightedHorse })}
                    style={{ animationDelay: `${rank * 200}ms` }}
                    color={horseColor}
                    orientation={transformRelY(y) < 0.5 ? 'l' : 'r'}
                    rotation={rotation}
                    onOpenToolTip={() => analytics?.logEvent('show_horse_info', { isOwnHorse })}>
                    <HorseInfo
                        {...{ playerName, horseName, isDropped }}
                        country={horsePlayer.country}
                        horseTotalRank={currentRound > 0 ? totalRank : undefined}
                        handicap={Turfmaster.handi[horseHandi]}
                        jockeyName={horse?.jockeyName}
                        {...(horseColor !== horseRealColor && { horseRealColor: HorseColorValues[horseRealColor] })}
                    />
                </HorseIcon>
            </div>
        </animated.div>
    );
};

export default memo(Horse);
