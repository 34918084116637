/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import * as Tooltip from '@radix-ui/react-tooltip';
import React, { memo, useMemo } from 'react';
import { Board, Field } from '~/server/src/tmmodel';
import { transformAbsX, transformAbsY, vh, vw } from '~/utils';

const horseSize = vw * 0.03;
const PADDING = horseSize / 6; // Padding distance from circle center
const ARROW_SIZE = horseSize / 10; // Size of arrow head

// Memoize the fields array
const useFields = () => {
    return useMemo(() => new Board(0).fields, []);
};

const FieldConnection = memo(({ fromField, toField }: { fromField: Field; toField: Field }) => {
    const x1 = transformAbsX(fromField.x);
    const y1 = transformAbsY(fromField.y);
    const x2 = transformAbsX(toField.x);
    const y2 = transformAbsY(toField.y);

    // Calculate direction vector
    const dx = x2 - x1;
    const dy = y2 - y1;
    const length = Math.sqrt(dx * dx + dy * dy);
    const unitX = dx / length;
    const unitY = dy / length;

    // Apply padding to start and end points
    const startX = x1 + unitX * PADDING;
    const startY = y1 + unitY * PADDING;
    const endX = x2 - unitX * PADDING;
    const endY = y2 - unitY * PADDING;

    // Calculate arrow points
    const angle = Math.PI / 6; // 30 degrees
    const arrowX1 = endX - ARROW_SIZE * (Math.cos(angle) * unitX - Math.sin(angle) * unitY);
    const arrowY1 = endY - ARROW_SIZE * (Math.sin(angle) * unitX + Math.cos(angle) * unitY);
    const arrowX2 = endX - ARROW_SIZE * (Math.cos(angle) * unitX + Math.sin(angle) * unitY);
    const arrowY2 = endY - ARROW_SIZE * (-Math.sin(angle) * unitX + Math.cos(angle) * unitY);

    return (
        <>
            <line x1={startX} y1={startY} x2={endX} y2={endY} stroke="rgba(0,0,0,0.2)" strokeWidth={2} />
            <polygon points={`${endX},${endY} ${arrowX1},${arrowY1} ${arrowX2},${arrowY2}`} fill="rgba(0,0,0,0.2)" />
        </>
    );
});

const FieldDataContent = memo(({ field }: { field: Field }) => {
    return (
        <div className="bg-white rounded-md py-2 px-3 shadow-lg font-mono text-sm">
            <table className="border-separate border-spacing-x-2">
                <tbody>
                    <tr>
                        <td className="text-gray-500">Position:</td>
                        <td>
                            ({field.x}, {field.y})
                        </td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Lane:</td>
                        <td>{field.lane}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Pos in Lane:</td>
                        <td>{field.pos}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Progress:</td>
                        <td>{field.prog}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Next Forward:</td>
                        <td>{field.nf}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Next Left:</td>
                        <td>{field.nl}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Next Right:</td>
                        <td>{field.nr}</td>
                    </tr>
                    <tr>
                        <td className="text-gray-500">Hurdle:</td>
                        <td>{field.hurdle ? 'Yes' : 'No'}</td>
                    </tr>
                </tbody>
            </table>
            <Tooltip.Arrow className="fill-white" />
        </div>
    );
});

const FieldMarker = memo(({ field, fields2D }: { field: Field; fields2D: Field[][] }) => {
    const cx = transformAbsX(field.x);
    const cy = transformAbsY(field.y);
    const lane = field.lane;

    return (
        <Tooltip.Provider>
            <Tooltip.Root delayDuration={0}>
                <Tooltip.Trigger asChild>
                    <g>
                        {/* Hurdle highlight ring */}
                        {field.hurdle && (
                            <circle
                                cx={cx}
                                cy={cy}
                                r={horseSize / 7}
                                fill="none"
                                stroke="#ff6b6b"
                                strokeWidth={3}
                                opacity={0.8}
                            />
                        )}
                        {/* Main field marker */}
                        <circle
                            key={`field-${field.x}-${field.y}`}
                            cx={cx}
                            cy={cy}
                            r={horseSize / 10}
                            fill={field.hurdle ? '#ff6b6b' : 'red'}
                            opacity={0.3}
                            stroke="black"
                            strokeWidth={5}
                            color="#000000"
                            style={{ cursor: 'pointer' }}
                        />
                    </g>
                </Tooltip.Trigger>

                <Tooltip.Portal>
                    <Tooltip.Content sideOffset={16} className="z-50">
                        <FieldDataContent field={field} />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>

            {/* Connections */}
            {field.nf >= 0 && fields2D[lane]?.[field.nf] && (
                <FieldConnection fromField={field} toField={fields2D[lane][field.nf]} />
            )}
            {field.nl >= 0 && lane > 0 && fields2D[lane - 1]?.[field.nl] && (
                <FieldConnection fromField={field} toField={fields2D[lane - 1][field.nl]} />
            )}
            {field.nr >= 0 && lane < fields2D.length - 1 && fields2D[lane + 1]?.[field.nr] && (
                <FieldConnection fromField={field} toField={fields2D[lane + 1][field.nr]} />
            )}
        </Tooltip.Provider>
    );
});

const FieldMarkerView = () => {
    const fields2D = useFields();
    const flatFields = fields2D.flat();

    return (
        <svg className="absolute inset-0 w-full h-full" viewBox={`0 0 ${vw} ${vh}`}>
            {flatFields.map((field) => (
                <FieldMarker key={`${field.x}-${field.y}`} field={field} fields2D={fields2D} />
            ))}
        </svg>
    );
};

export default memo(FieldMarkerView);
