/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { EventChannel } from 'redux-saga';
import { all, put, take } from 'redux-saga/effects';
import { getFirebaseAnalytics } from '~/apis/firebase';
import { setConnected, setCurrentState } from '~/game';
import {
    setChatMessages,
    setLastCardHorseIndex,
    setLastTurnDicePlayerId,
    setLastTurnDices,
    setLastTurnDicesCur,
} from '~/overlay';
import { GameState, GameType } from '~/server/src/dtos';
import { Controller, View } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';
import { actions as setupActions, RestoreGameParameters } from '~/setup';

function* sagaRestoreGame(
    view: View,
    controller: Controller,
    restoreGameParams: RestoreGameParameters,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    channel: EventChannel<any>,
) {
    if (!view || !controller || !channel) return;

    const analytics = getFirebaseAnalytics();

    yield put(setupActions.setRestoreState('loading'));

    controller.receiveMsg(Turfmaster.stateMachine.RESTOREGAME, { view });
    const { state, params } = yield take(channel);

    if (state === Turfmaster.stateMachine.RESTOREGAME) {
        console.log('controller => client:', 'RESTOREGAME', { state, params });
        const restorableGameState: GameState | undefined = params?.game;

        if (restorableGameState) {
            // a game that could be restored does exist
            controller.receiveMsg(Turfmaster.stateMachine.REJOINGAME, { view });
            const { state, params } = yield take(channel);

            const game = controller.getGame();
            const board = controller.getBoard();

            if (state === Turfmaster.stateMachine.REJOINGAME && game && board) {
                console.log('controller => client:', 'REJOINGAME', { state, params }, game, board);

                const lastTurnDices = game.turndices.length > 0 ? game.turndices : [-1, -1];

                // rejoin successful
                yield all([
                    put(setupActions.setPlayerId(params.playerid)),
                    put(setupActions.setGameId(restorableGameState.gameId)),
                    ...[
                        restoreGameParams.mode === 'training' ? put(setupActions.setGameType(GameType.Training)) : [], // TODO: extend for other game types on restore?
                    ],
                    put(setCurrentState(game.currentState)),
                    put(setChatMessages(restorableGameState.chatMessages)),
                    put(setConnected(true)),
                    put(setupActions.setRestoreState('success')),

                    // try to reconstruct lastTurnDice player and array
                    put(setLastTurnDicePlayerId(game.turndiceplayerid)),
                    put(setLastTurnDices(lastTurnDices)),
                    put(setLastTurnDicesCur(game.turndicescur)),

                    // try to reconstruct last horse card index, card value is not possible
                    put(
                        setLastCardHorseIndex(
                            (game.turntype === Turfmaster.turntypes.CARDS
                                ? game.turnorder[(game.turnpos + game.turnpos - 1) % game.turnpos]
                                : -1) ?? -1,
                        ),
                    ),
                ]);

                // if state is selectmove while restoring, send SELECTCARDDICE with -1 to get moves via params again
                if (game.currentState === Turfmaster.stateMachine.SELECTMOVE) {
                    const horseid = game.turnorder[game.turnpos];
                    controller.receiveMsg(Turfmaster.stateMachine.SELECTCARDDICE, {
                        playerid: params.playerid,
                        horseid,
                        carddiceid: -1,
                    });
                }

                analytics?.logEvent('restore_game', {
                    connectionType: controller.getType(),
                    playerCount: game.players.length,
                    horseCountPerPlayer: game.horses.length / game.players.length,
                });

                return true;
            } else {
                // tbd handle unsuccessful rejoin
                console.log('controller => client:', 'UNKNOWN RESPONSE TO REJOINGAME', { state, params });
            }
        } else {
            // tbd no game available
            console.log('game is null');
        }
    } else {
        // tbd wrong response
        console.log('controller => client:', 'UNKNOWN RESPONSE TO RESTOREGAME', { state, params });
    }

    yield put(setupActions.setRestoreState('error'));

    return false;
}

export default sagaRestoreGame;
