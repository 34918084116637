/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHorsesBehindGoal, selectHorsesStarted, selectHurdles, selectRound } from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { transformAbsX, transformAbsY, vh, vw } from '~/utils';
import * as styles from './HurdleView.module.css';

const hurdleSize = 60;

const HurdleView = () => {
    const round = useSelector(selectRound);
    const hurdles = useSelector(selectHurdles);
    const horsesStarted = useSelector(selectHorsesStarted);
    const horsesBehindGoal = useSelector(selectHorsesBehindGoal);

    // generate svg paths for each hurdle
    const hurdlePaths: string[] = useMemo(() => {
        // if not a hurdle round than render nothing
        if (!Turfmaster.hurdleRounds.includes(round)) return [];

        // convert hurdle field array into an svg path
        const hurdlePaths: string[] = [];

        const isFirstHurdleVisible = !horsesBehindGoal.reduce((prev, cur) => cur && prev);
        const isLastHurdleVisible = horsesStarted.reduce((prev, cur) => cur && prev);

        hurdles
            .filter((_hurdle, index) => {
                if (index === 0) {
                    // first hurdle is at the goal line and should only be visible if not all horses
                    // already went behind the hurdle
                    return isFirstHurdleVisible;
                } else if (index === hurdles.length - 1) {
                    // last hurdle is the start line hurdle which should only be visible if all horses left
                    // the start so that they don't overlap
                    return isLastHurdleVisible;
                } else return true;
            })
            .forEach((hurdle, hurdleIndex, filteredHurdles) => {
                let d = '';

                const fields =
                    (isFirstHurdleVisible && hurdleIndex === 0) ||
                    (isLastHurdleVisible && hurdleIndex === filteredHurdles.length - 1)
                        ? hurdle.slice(0, -2)
                        : hurdle;

                fields.forEach((field, index) => {
                    d += `${index === 0 ? 'M' : 'L'}${transformAbsX(field.x)} ${transformAbsY(field.y)}`;
                });
                hurdlePaths.push(d);
            });

        return hurdlePaths;
    }, [horsesBehindGoal, horsesStarted, hurdles, round]);

    return (
        <svg className={styles.HurdleViewRoot} viewBox={`0 0 ${vw} ${vh}`}>
            <defs>
                <pattern id="fancy-rectangles" x="0" y="0" width="60" height="48" patternUnits="userSpaceOnUse">
                    <rect fill="#125214" width="60" height="48" />
                    <path
                        fill="transparent"
                        stroke="#d3d3d3"
                        opacity={0.3}
                        d="M6 12h6v12H6V12zm12 0h6v12h-6V12zm6-12h6v12h-6V0zM12 0h6v12h-6V0zm0 24h6v12h-6V24zM0 0h6v12H0V0zm6 36h6v12H6V36zm12 0h6v12h-6V36zm12-12h6v12h-6V24zM42 0h6v12h-6V0zm-6 12h6v12h-6V12zm12 0h6v12h-6V12zM36 36h6v12h-6V36zm12 0h6v12h-6V36zm-6-12h6v12h-6V24zm12 0h6v12h-6V24z"
                    />
                </pattern>
            </defs>
            {hurdlePaths.map((hurdlePath, index) => (
                <path
                    key={index}
                    d={hurdlePath}
                    className={styles.Hurdle}
                    style={{ animationDelay: `${index * 200}ms` }}
                    stroke="url(#fancy-rectangles)"
                    strokeWidth={hurdleSize}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                />
            ))}
        </svg>
    );
};

export default memo(HurdleView);
